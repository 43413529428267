import { valueIsNumber } from '@/modules/tariff/shared/helpers';
import { useAppSelector } from '@/store/hooks';

export const useCountBasketPrices = () => {
  const {
    totalPriceHomeInternet,
    promotionTotalPriceHomeInternet,
    status: statusHomeInternet,
  } = useAppSelector((store) => store.homeInternet);

  const {
    totalPriceMobile,
    promotionTotalPriceMobile,
    totalPriceMobileShpd,
    promotionTotalPriceMobileShpd,
    status: statusMobileTariff,
  } = useAppSelector((store) => store.mobileTariff);

  const currentMobilePrice = statusHomeInternet.added ? totalPriceMobileShpd : totalPriceMobile;
  const currentPromotionMobilePrice = statusHomeInternet.added ? promotionTotalPriceMobileShpd : promotionTotalPriceMobile;

  const { selectedNumber } = useAppSelector((store) => store.beautifulNumber);

  const totalBasketPrice =
    (statusHomeInternet.added ? totalPriceHomeInternet : 0) +
    (statusMobileTariff.added ? currentMobilePrice : 0) +
    (selectedNumber ? selectedNumber.price || 0 : 0);

  const totalBasketPriceWithPromotionNumber =
    (statusHomeInternet.added
      ? valueIsNumber(promotionTotalPriceHomeInternet)
        ? promotionTotalPriceHomeInternet || 0
        : totalPriceHomeInternet
      : 0) +
    (statusMobileTariff.added
      ? valueIsNumber(currentPromotionMobilePrice)
        ? currentPromotionMobilePrice || 0
        : currentMobilePrice
      : 0) +
    (selectedNumber ? selectedNumber.price || 0 : 0);

  const totalBasketPriceWithPromotion = totalBasketPriceWithPromotionNumber < totalBasketPrice ? 
    totalBasketPriceWithPromotionNumber : undefined;

  return { totalBasketPrice, totalBasketPriceWithPromotion };
};
